<template>
  <v-container>
    <v-sheet class="mb-4">
      <v-card class="pa-3">
        <v-card-title>
          <h1 v-if="isNumericalClosed">Teams Numerical Registration</h1>
          <h1 v-if="!isNumericalClosed && !isAdmin">Teams Roll Call</h1>
          <h1 v-if="isAdmin">Teams Admin-Overview</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Number Of Persons: {{ allPeople }}<br />
              Fee Total: {{ currencyFormatter.format(feeTotal) }}
            </v-col>
            <v-col>
              Num of, Breakfast: {{ foodTotal.breakfast }},
              Lunch: {{ foodTotal.lunch }},
              Dinner: {{ foodTotal.dinner }}
              <br />
              Food allowance: {{ currencyFormatter.format(foodFee) }}
            </v-col>
          </v-row>
          <v-row v-if="isAdmin">
            <v-btn @click="exportFile" color="green-lighten-1">
              Excel
              <v-icon icon="mdi-download-outline"></v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
          <v-dialog persistent width="70%" scrollable>
            <template v-slot:activator="{ props }">              
                <v-col align="end">
                  <v-btn variant="tonal" :color="!regNumericClosed ? 'success': 'info'" v-bind="props" v-if="!disabled">
                    {{ regNumericClosed == true ? 'Show Summary' : 'Finalize Numerical Registration' }}
                  </v-btn>
                </v-col>
            </template>
            <template v-slot:default="{ isActive }">
              <v-card ref="Summary">
                <v-toolbar color="blue-grey-darken-2">
                  <v-toolbar-title>
                    Fees For Participation And Catering Allowance - European Championships 2023
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <p>
                        Fees for participation and catering allowance.
                        With the transmission of the data the following fees are due for the participants and food.
                      </p>
                      <p>This form will be locked after submision.</p>
                    </v-col>
                  </v-row>
                  <div v-for="team, i in teams" :key="i">
                    <v-row>
                      <v-col cols="1">
                        {{ i+1 }}.
                      </v-col>
                      <v-col>
                        Team: {{ team.team_name }}, {{ translateTeamClass(team.team_class) }}<br />
                        {{ team.num_of_competitors }} Competitors<span v-if="team.num_of_others>0">, {{ team.num_of_others }} accompanying person{{
                          team.num_of_others > 0 ? 's' : '' }}</span><br />
                        Meals overall: {{ foodNumericTeam(team) }}
                      </v-col>
                      <v-col align="end"><br />
                        {{ currencyFormatter.format((team.num_of_competitors + team.num_of_others) * fee) }} <br />
                        {{ currencyFormatter.format(foodPerTeam(team)) }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="1"></v-col>
                    <v-col class="align-end justify-end">
                      Total:
                    </v-col>
                    <v-col class="align-end justify-end" align="end">
                      {{ currencyFormatter.format(feeTotal + foodFee) }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>
                        Please pay the full amount of {{ currencyFormatter.format(feeTotal + foodFee) }} until {{ payDate() }} quoting the reference "{{ teamReference() }}" to the following bank account.</p>
                        <p>
                          <b>Credit Institut</b>: MBS Potsdam
                      <br />
                      <b>Owner: KCB e.V. represented by</b>: Michael Gußer
                      <br />
                      <b>IBAN</b>: DE19 1605 0000 1000 6407 67
                      <br />
                      <b>BIC</b>: WELAD1PMB
                    </p>
                    <p>If you have any questions, please do not hesitate to contact us at
                    <a href="mailto:info@poloeca2023.org">info@poloeca2023.org</a>
                    </p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end px-4 py-4">
                  <v-btn color="success" variant="tonal" @click="printSummary()">Print</v-btn>
                  <v-btn v-if="!regNumericClosed" color="success" variant="tonal" @click="submitNumerical(),isActive.value = false">Submit</v-btn>
                  <v-btn color="warning" variant="tonal" @click="isActive.value = false">Cancel</v-btn>
                </v-card-actions>
                
              </v-card>
            </template>
          </v-dialog>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
    <v-sheet class="bg-transparent" >
      <v-row>
        <v-col v-for="(team, index) in teams" :key="index" cols="12" sm="12" md="12" xl="6">
          <div ></div>
          <v-card v-if="team.members.length > 0 && team.reg_numerical_closed">
            <v-card-title>
              {{ team.team_name }} - {{ translateTeamClass(team.team_class) }}
            </v-card-title>
            <v-card-text>
              <div v-for="(member, index) in team.members" :key="index" no-gutters align="center" class="my-2">
                  <PersonComponent :person="member" :index="index"  @update:data="updateData" :ref="`${member.team_id}-${index+1}`" :disabled="isAdmin"/>
              </div>
            </v-card-text>
            <v-card-text>
              <v-btn variant="tonal" color="success" :disabled="isAdmin" @click="saveAllMembers()"><v-icon icon="mdi-content-save-all" class="mr-2" ></v-icon> Save All</v-btn>
            </v-card-text>
          </v-card>
          
          <v-card v-if="team.members.length === 0 " class="px-3 py-3" :disabled="team.reg_numerical_closed">
            <v-card-title class="text-uppercase justify-center">
              <v-icon size="x-small" v-if="team.reg_numerical_closed && isAdmin">mdi-lock</v-icon> {{ team.team_name }}
              <v-chip>
                {{ translateTeamClass(team.team_class) }}
              </v-chip>
            </v-card-title>
            <v-card-subtitle>
              <div class="d-flex justify-space-between flex-row">
                <p>Number Of Persons: {{ team.num_of_competitors + team.num_of_others }} </p>
                <p>Fee: {{ currencyFormatter.format((team.num_of_competitors + team.num_of_others) * fee) }}</p>
              </div>
              <div class="d-flex justify-space-between flex-row">
                <p>Number Of Meals: {{ foodNumericTeam(team) }} </p>
                <p>Food: {{ foodPerTeam(team) }},00 €</p>
              </div>
            </v-card-subtitle>
            <v-card-text>
              <div class="text-caption">Competitors: {{ team.num_of_competitors }}</div>
              <v-slider :disabled="!editable[team.team_class] || disabled" v-model="team.num_of_competitors" :step="1"
                :max="maxCompetitors" :min="minCompetitors" thumb-label>
                <template v-slot:prepend>
                  <v-btn size="small" variant="text" icon="mdi-account-minus"
                    @click="team.num_of_competitors - 1 < minCompetitors ? team.num_of_competitors = minCompetitors : team.num_of_competitors -= 1">
                  </v-btn>
                </template>
                <template v-slot:append>
                  <v-btn size="small" variant="text" icon="mdi-account-plus"
                    @click="team.num_of_competitors + 1 > maxCompetitors ? team.num_of_competitors = maxCompetitors : team.num_of_competitors += 1">
                  </v-btn>
                </template>
              </v-slider>
              <v-divider class="mb-3"></v-divider>
              <div class="text-caption">Others: {{ team.num_of_others }}</div>
              <v-slider :disabled="!editable[team.team_class] || disabled" v-model="team.num_of_others" :step="1"
                :max="maxPerson" :min="0" thumb-label>
                <template v-slot:prepend>
                  <v-btn size="small" variant="text" icon="mdi-account-minus"
                    @click="team.num_of_others - 1 < 0 ? team.num_of_others = 0 : team.num_of_others -= 1">
                  </v-btn>
                </template>
                <template v-slot:append>
                  <v-btn size="small" variant="text" icon="mdi-account-plus" @click="team.num_of_others += 1">
                  </v-btn>
                </template>
              </v-slider>
            </v-card-text>
            <v-card-actions v-if="!disabled">
              <v-row>
                <v-col v-if="disabled || !editable[team.team_class]" cols="3">
                  <v-btn block color="info" variant="tonal" @click="editMode(team.team_class)">
                    <v-icon class="mr-1">mdi-pencil</v-icon> Edit
                  </v-btn>
                </v-col>
                <v-col v-if="disabled || editable[team.team_class]" cols="3">
                  <v-btn block color="success" variant="tonal"
                    @click="saveCompetitors(team.id, team), editMode(team.team_class)">
                    <v-icon class="mr-1">mdi-content-save</v-icon> Save
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col align="end">
                  <v-dialog persistent width="90%">
                    <template v-slot:activator="{ props }">
                      <v-btn variant="tonal" :disabled="disabled || editable[team.team_class]" color="info" class=""
                        v-bind="props">
                        <v-icon class="mr-2">mdi-food-variant</v-icon> Choose Food</v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-card>
                        <v-toolbar color="blue-grey-darken-2">
                          <v-toolbar-title>
                            Select Food For "{{ team.team_name }}" <v-chip>{{ translateTeamClass(team.team_class)
                            }}</v-chip>
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-row dense>
                            <v-col>Date</v-col>
                            <v-col>Breakfast</v-col>
                            <v-col>Lunch</v-col>
                            <v-col>Dinner</v-col>
                            <v-col>Vegetarian</v-col>
                            <v-col>Actions</v-col>
                          </v-row>
                          <v-row dense v-for="(food, i) in team.food" :key="i">
                            <v-col>{{ formatDate(food.date_of_food) }}</v-col>
                            <v-col>
                              <v-select density="compact" v-model="food.number_breakfast"
                                :items="numOfPeople(team.num_of_others + team.num_of_competitors)"></v-select>
                            </v-col>
                            <v-col>
                              <v-select density="compact" v-model="food.number_lunch"
                                :items="numOfPeople(team.num_of_others + team.num_of_competitors)"></v-select>
                            </v-col>
                            <v-col>
                              <v-select density="compact" v-model="food.number_dinner"
                                :items="numOfPeople(team.num_of_others + team.num_of_competitors)"></v-select>
                            </v-col>
                            <v-col>
                              <v-select density="compact" v-model="food.number_vegetarian"
                                :items="numOfPeople(team.num_of_others + team.num_of_competitors)"></v-select>
                            </v-col>
                            <v-col>
                              <v-btn-group divided variant="outlined" density="compact">
                                <v-btn size="x-small" @click="chooseFood(food, team.num_of_competitors + team.num_of_others)">All</v-btn>
                                <v-btn size="x-small" @click="chooseFood(food, 0)">None</v-btn>
                              </v-btn-group>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col></v-col>
                            <v-col align="center">
                              <v-btn-group divided variant="outlined" density="compact">
                                <v-btn size="x-small"
                                  @click="chooseFoodPerDay(team, 'breakfast', team.num_of_competitors + team.num_of_others)">All</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'breakfast', 0)">None</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'breakfast', 1, true)">
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'breakfast', -1, true)">
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </v-btn-group>
                            </v-col>
                            <v-col align="center">
                              <v-btn-group divided variant="outlined" density="compact">
                                <v-btn size="x-small"
                                  @click="chooseFoodPerDay(team, 'lunch', team.num_of_competitors + team.num_of_others)">All</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'lunch', 0)">None</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'lunch', 1, true)">
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'lunch', -1, true)">
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </v-btn-group>
                            </v-col>
                            <v-col align="center">
                              <v-btn-group divided variant="outlined" density="compact">
                                <v-btn size="x-small"
                                  @click="chooseFoodPerDay(team, 'dinner', team.num_of_competitors + team.num_of_others)">All</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'dinner', 0)">None</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'dinner', 1, true)">
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'dinner', -1, true)">
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </v-btn-group>
                            </v-col>
                            <v-col align="center">
                              <v-btn-group divided variant="outlined" density="compact" size="x-small">
                                <v-btn size="x-small"
                                  @click="chooseFoodPerDay(team, 'vegetarian', team.num_of_competitors + team.num_of_others)">All</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'vegetarian', 0)">None</v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'vegetarian', 1, true)">
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn size="x-small" @click="chooseFoodPerDay(team, 'vegetarian', -1, true)">
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </v-btn-group>
                            </v-col>
                            <v-col></v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end px-4 py-4">
                          <v-btn color="success" variant="tonal"
                            @click="saveFoodSelection(team), isActive.value = false">Save</v-btn>
                          <v-btn color="warning" variant="tonal" @click="isActive.value = false">Cancel</v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>

  </v-container>
</template>
  
<script>

import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';
import moment from 'moment';
import axios from 'axios';
import { utils, writeFileXLSX } from "xlsx";
import PersonComponent from '@/components/Person.vue'

export default defineComponent({
  name: 'TeamManagementView',
  components: {
      PersonComponent
    },
  data() {
    return {
      hover: false,
      imageData: null,
      done: true,
      tabs: null,
      saved: false,
      fee: 240,
      maxPerson: 10,
      minCompetitors: 5,
      maxCompetitors: 10,
      feeBreakfast: 7,
      feeLunch: 12,
      feeDinner: 10,
      feeCupoun: 25,
      foodSelectItems: [],
      editable: {
        M: false,
        MU21: false,
        W: false,
        WU21: false
      },
      regNumericClosed: false,
      regNamedClosed: false,
      currencyFormatter: new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }),
      dialog: false
    }
  },
  beforeMount: function () {
    // console.log('before mounted');
  },
  created: function () {
    this.$store.dispatch('getMyTeams');
    return;
  },
  watch: {
    teams: {
      handler(old, newvalue) {
        if(old.length > 0 && newvalue.length == 0) {
          for (let i = 0; i < old.length; i++) {
            if(old[i].reg_numerical_closed) {
              this.regNumericClosed = true;
              break;
            }
          }
          return;
        }

        for (let i = 0; i < newvalue.length; i++) {
          this.foodSelectItems[newvalue[i].team_class] = this.numOfPeople(newvalue[i].num_of_competitors + newvalue[i].num_of_others);
          if(newvalue[i].reg_numerical_closed) {
            this.regNumericClosed = true;
          }
        }
      },
      deep: true
    },
    dialog (val) {
        if (!val) return

        setTimeout(() => (this.dialog = false), 500)
      },
  },
  computed: {
    ...mapGetters({ teams: 'stateMyTeams' }),
    isAdmin() {
      const _userRoles = store.state.users.roles === null ? null : store.state.users.roles;
      if (_userRoles !== null) {

        for (let role in _userRoles) {
          if (_userRoles[role] === 'admin') {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * @deprecated
     */
    disabled() {
      const _userRoles = store.state.users.roles === null ? null : store.state.users.roles;
      if (_userRoles !== null) {

        for (let role in _userRoles) {
          if (_userRoles[role] === 'admin') {
            return true;
          }
        }
      }
      return false;
    },
    allPeople() {
      let sum = 0;
      this.teams.map(function (item) {
        sum += item.num_of_competitors + item.num_of_others;
      });
      return sum;
    },
    feeTotal() {
      let sum = 0;
      this.teams.map((item) => {
        sum += (item.num_of_competitors * this.fee);
        sum += (item.num_of_others * this.fee);
      });

      return sum;
    },
    foodTotal() {
      const result = { breakfast: 0, lunch: 0, dinner: 0 };

      this.teams.map((item) => {
        for (let i = 0; i < item.food.length; i++) {
          result.breakfast += item.food[i].number_breakfast;
          result.lunch += item.food[i].number_lunch;
          result.dinner += item.food[i].number_dinner;
        }
      });

      return result;
    },
    foodPerTeam() {
      return (team) => {
        let _price = 0;

        for (let i = 0; i < team.food.length; i++) {
          const numOfRabatt = Math.min(
            team.food[i].number_breakfast, team.food[i].number_lunch, team.food[i].number_dinner
          )

          _price += (team.food[i].number_breakfast * this.feeBreakfast) + 
          (team.food[i].number_lunch * this.feeLunch) + 
          (team.food[i].number_dinner * this.feeDinner) - 
          (numOfRabatt * 4)          
        }

        return _price;
      }
    },
    foodNumericTeam() {
      return (team) => {
        let _result = 0;
        for (let i = 0; i < team.food.length; i++) {
          _result += team.food[i].number_breakfast;
          _result += team.food[i].number_lunch;
          _result += team.food[i].number_dinner;
        }
        return _result;
      }
    },
    foodFee() {
      let _price = 0

      this.teams.map((item) => {
        for (let i = 0; i < item.food.length; i++) {
          const numOfRabatt = Math.min(
            item.food[i].number_breakfast, item.food[i].number_lunch, item.food[i].number_dinner
          )

          _price += (item.food[i].number_breakfast * this.feeBreakfast) + 
          (item.food[i].number_lunch * this.feeLunch) + 
          (item.food[i].number_dinner * this.feeDinner) - 
          (numOfRabatt * 4)          
        }
      });

      return _price;
    },
    isNumericalClosed() {
      let _closed = false;
      for(const team in this.teams) {
        if(team.reg_numerical_closed) {
          _closed = true;
        }
      }
      return _closed;
    }
  },
  methods: {
    ...mapActions(['updateMyTeam']),
    numOfPeople(length) {
      const result = [0];
      for (let i = 0; i < length; i++) {
        result.push(i + 1);
      }
      return result;
    },
    editMode(team_class) {
      this.editable[team_class] = !this.editable[team_class];
    },
    saveCompetitors(id, team) {
      this.updateMyTeam(team)
      // TODO check team count
      const _maxP = team.num_of_competitors + team.num_of_others;
      team.food.map((item) => {
        item.number_breakfast = item.number_breakfast > _maxP ? _maxP : item.number_breakfast;
        item.number_lunch = item.number_lunch > _maxP ? _maxP : item.number_lunch;
        item.number_dinner = item.number_dinner > _maxP ? _maxP : item.number_dinner;
      });
    },
    formatDate(date) {
      const _date = moment(date);
      return _date.format('DD.MM.YY');
    },
    chooseFoodPerDay(team, food, number, increment=false) {
      for (let i = 0; i < team.food.length; i++) {
        if(increment) {
          const _actualNumOfFood = team.food[i]['number_' + food];
          if(number == 1 && _actualNumOfFood < (team.num_of_competitors+team.num_of_others) ) {
            team.food[i]['number_' + food] += number;
          } 
          if(number == -1 && _actualNumOfFood > 0 ) {
            team.food[i]['number_' + food] += number;
          } 
        } else {
          team.food[i]['number_' + food] = number;
        }
      }
    },
    chooseFood(food, number) {
      food.number_breakfast = number;
      food.number_lunch = number;
      food.number_dinner = number;
      if (number == 0) {
        food.number_vegetarian = 0;
      }
    },
    translateTeamClass(team_class) {
      const classes = {
        M: 'Men',
        MU21: 'Men U21',
        W: 'Women',
        WU21: 'Women U21'
      }
      return classes[team_class]
    },
    submitNumerical(){
      let ids = new Array();
      this.teams.map(item => {
        ids.push(item.id);
        item.reg_numerical_closed = true;
      });
      axios
        .post('/close_numerical', ids)
        .then((data) => {
          if(data.success) {
            console.log('..t')
            this.regNumericClosed = true;
          }
        })
        .catch(error => {console.log("error closing numerical: %O", error)});
    },
    payDate() {
      let result = null;

      this.teams.map(item => {        
        if (result == null) {
          result = moment(item.time_updated);
        } else {
          if (moment(item.time_updated).isAfter(result)) {
            result = item.time_updated;
          }
        }
      });
      result = moment(result).add(10, 'days');
      if(result.isValid()) {
        return result.format('DD.MM.YY');
      } else {
        const _dateTime = moment().add(10, 'days');
        return _dateTime.format('DD.MM.YY');
      }
      
    },
    getTeamReference(team_lead_id) {
      let _result = null;

      this.teams.map((item) => {
        if(item.team_lead_id == team_lead_id) {
          if (_result == null) {
            _result = moment(item.time_updated);
          } else {
            if (moment(item.time_updated).isAfter(_result)) {
              _result = moment(item.time_updated);
            }
          }

        }
      });

      if(_result !== null && _result.isValid()) {
        const _ref = moment(_result);
        return `${_ref.format('YYMMDD')}-${team_lead_id}`;
      } else {
        const _dateTime = moment();
        return `${_dateTime.format('YYMMDD')}-${team_lead_id}`;
      }
    },
    moment(days) {
      const _dateTime = moment();
      _dateTime.add(days, 'days')
      return _dateTime.format('DD.MM.YY');
    },
    teamReference() {
      let result = null;
      this.teams.map(item => {
        if (result == null) {
          result = moment(item.time_updated);
        } else {
          if (moment(item.time_updated).isAfter(result)) {
            result = moment(item.time_updated);
          }
        }
      });
      if(result.isValid()) {
        const _ref = moment(result);
        return `${_ref.format('YYMMDD')}-${this.teams[0].team_lead_id}`;
      } else {
        const _dateTime = moment();
        return `${_dateTime.format('YYMMDD')}-${this.teams[0].team_lead_id}`;
      }
    },
    saveFoodSelection(team) {
      this.updateMyTeam(team);
    },
    exportFile() {

      const _myNewTeams = new Array();
      const wb = utils.book_new();

      const curencyFormat = '#,##0.00€;[Red]-#,##0.00€';
      const dateFormat = 'dd.mm.yy';

      for(const item in this.teams) {
        if(this.teams[item].team_name in _myNewTeams) {
          _myNewTeams[this.teams[item].team_name].push(this.teams[item]);
        } else {
          _myNewTeams[this.teams[item].team_name] = [this.teams[item]];
        }
      }

      for(const team in _myNewTeams) {
        const _team = _myNewTeams[team];
        const _ws = utils.aoa_to_sheet([
          ['Nation: ', '', team],
        ])

        let nations = [];
        let people = 0;
        let sumFood = [];

        utils.sheet_add_aoa(_ws, [
          ['Teams:', '', 'TEAMS'],
          ['Personen: ', '', people],
          ['Akkreditierung: ', '', {t: 'n', f: 'C3*240', z: curencyFormat}],
          ['Essen: ', '', {t: 'n', f: 0, z: curencyFormat}],
          ['Gesamt: ', '', {t: 'n', f: 'C4+C5', z: curencyFormat}],
          ['Ref', '', this.getTeamReference(_team[0].team_lead_id)],
          ['','','']
        ], {origin: -1})


        for(const _sub in _team) {
          const _subTeam = _team[_sub];
          const _teamClass = _subTeam.team_class;
          nations.push(this.translateTeamClass(_teamClass))
          people += _subTeam.num_of_competitors + _subTeam.num_of_others;
          const _offset = 10;

          utils.sheet_add_aoa(_ws, [
            [this.translateTeamClass(_teamClass)]
          ], {origin: -1});

          utils.sheet_add_aoa(_ws, [
            ['Datum', 'Frühstück', 'Mittag', 'Abend', 'Vegetarisch', 'Preis']
            ], {origin: -1});

          let _zeile = 0;
          for(const foodIdx in _subTeam.food) {
            const _food = _subTeam.food[foodIdx];

            _zeile = 11 + parseInt(foodIdx,10) + (_offset * parseInt(_sub, 10));

            const _toAdd = [
                {v: _food.date_of_food, z: dateFormat}, _food.number_breakfast, _food.number_lunch, _food.number_dinner, _food.number_vegetarian, 
                {t: 'n', f:`=(B${_zeile}*${this.feeBreakfast})+(C${_zeile}*${this.feeLunch})+(D${_zeile}*${this.feeDinner})-(MIN(B${_zeile}:D${_zeile})*4)`, z: curencyFormat}
            ];
            utils.sheet_add_aoa(_ws, [_toAdd], {origin: -1});            
          }
          
          utils.sheet_add_aoa(_ws, [['', '','', '', '', {t: 'n', f:`=SUM(F${_zeile}:F${_zeile-6})`, z: curencyFormat}]], {origin: -1});
          sumFood.push(`f${_zeile+1}`)

          // for(let i = 0; i < 7; i++) {
          //   const _toadd = [
          //     {v:`${i+4}.09.2023`, z: dateFormat}, _subTeam.number_breakfast, _subTeam.number_lunch, _subTeam.number_dinner, _subTeam.number_vegetarian, {t: 'n', f:`=(B${i+_offset}*${this.feeBreakfast})+(C${i+_offset}*${this.feeLunch})+(D${i+_offset}*${this.feeDinner})-(MIN(B${i+_offset}:D${i+_offset})*4)`, z: curencyFormat}
          //   ]
            
          // }
        }


        _ws.name = _team
        _ws['C2'].v = nations.join(', ')
        _ws['C3'].v = people;
        _ws['C5'].f = `=SUM(${sumFood.join(',')})`

        utils.book_append_sheet(wb, _ws, team);
      }
      
    
      // const ws = utils.aoa_to_sheet([
      //   ['Nation: ', '', 'Deutschland'],
      //   ['Teams: ', '', 'Men, MenU21, Women, WomenU21'],
      //   ['Personen: ', '', 50],
      //   ['Akkreditierung: ', '', {t: 'n', f: 'C3*240', z: curencyFormat}],
      //   ['Essen: ', '', {t: 'n', v: 7000, z: curencyFormat}],
      //   ['Gesamt: ', '', {t: 'n', f: 'C4+C5', z: curencyFormat}],
      //   // ['negativ: ', '', {t: 'n', v: -500, z: curencyFormat}],
      // ]);

      // const _offset = 10;
      // for(let i = 0; i < 7; i++) {
      //   const _toadd = [
      //     {v:`${i+4}.09.2023`, z: dateFormat}, 10, 10, 10, 0, {t: 'n', f:`=(B${i+_offset}*${this.feeBreakfast})+(C${i+_offset}*${this.feeLunch})+(D${i+_offset}*${this.feeDinner})-(MIN(B${i+_offset}:D${i+_offset})*4)`, z: curencyFormat}
      //   ]
      //   utils.sheet_add_aoa(ws, [_toadd], {origin: -1});
      // }

      


      // utils.sheet_add_json(ws, {
      //   B1: {t:'s', v: 'Deutschland'}
      // })

      // const _date = (moment()).format("YYYYMMDD");
      
      // ws['!cols'] = [
      //   {'wch': this.getMaxWidth('Team')},
      //   {'wch': this.getMaxWidth('Mail')},     
      //   {'wch': this.getMaxWidth('Lead')},    
      //   {'wch': this.getMaxWidth('Phone')}        
      // ];
      // console.log(ws);
      // utils.book_append_sheet(wb, ws, "Deutschland");

      // writeFileXLSX(wb, `${_date}-teams_übersicht.xlsx`);
      writeFileXLSX(wb, `teams_übersicht.xlsx`);
    },
    printSummary() {
          let w = window.open()
          w.document.write(this.$refs.Summary.$el.innerHTML);
          w.document.close();
          const _body = w.document.querySelector('body');
          _body.style.padding = "25px";

          let _buttons = w.document.querySelectorAll('button');
          for(let i=0; i < _buttons.length; i++) {
            _buttons[i].remove();
          }

          const _rows = w.document.querySelectorAll('.v-row');
          for(let i=0; i < _rows.length; i++) {
            _rows[i].style.display='flex';
            _rows[i].style.flexWrap='wrap';
          }

          const _cols = w.document.querySelectorAll('.v-col');
          for(let i=0; i < _cols.length; i++) {
            _cols[i].style.flexBasis='0';
            _cols[i].style.flexGrow='1';
            _cols[i].style.maxWidth='100%';
          }

          const _col1 = w.document.querySelectorAll('.v-col-1');
          for(let i=0; i < _col1.length; i++) {
            _col1[i].style.flex='0 0 8.3333333333%';
            _col1[i].style.maxWidth='8.3333333333%';
            
          }

          w.setTimeout(function () {
            w.print()
          }, 1000)
    },
    saveAllMembers() {
      for ( let ref in this.$refs) {
        const _personComponent = this.$refs[ref][0];

        if(_personComponent.isDirty) {
          _personComponent.savePlayer();
        }
        
      }
    },
    updateData() {
    
    }
  },
});
</script>
<style scoped lang="scss">
.mail {
  text-decoration: none;
}

@media print {
  
  button {
    display: none;
  }
  
}

.passImg {
    aspect-ratio: 35/45;
    border: solid 1px grey;
    max-height: 55px;
    height: 55px;
    border-radius: 5px;
}

.addButton {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
        display: block;
    }
}

.file-input {
    display: none;
}
</style>