<template>
    <section>
      <h1>Your Profile</h1>
      <hr/><br/>
      <div v-if="user">
        <p><strong>Account:</strong> <span>{{ user.username }}</span></p>
        <p><strong>Username:</strong> <span>{{ user.full_name }}</span></p>
        <p><strong>E-Mail:</strong> <span>{{ user.email }}</span></p>
        <!-- <p><button @click="deleteAccount()" class="btn btn-primary">Delete Account</button></p> -->
      </div>
    </section>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
//   import { mapGetters, mapActions } from 'vuex';
  import { mapGetters } from 'vuex';
//   import store from '../store/index'
  
  export default defineComponent({
    name: 'ProfileView',
    // created: function() {
    //   return this.$store.dispatch('viewMe', store.getters.stateUser.data);
    // },
    data() {
        return {
            aa: null
        }
    },
    computed: {
        ...mapGetters({user: 'stateUser'})
    },
    methods: {
    //   ...mapActions(['deleteUser']),
    //   async deleteAccount() {
    //     try {
    //       await this.deleteUser(this.user.id);
    //       await this.$store.dispatch('logOut');
    //       this.$router.push('/');
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    },
  });
  </script>