import axios from 'axios';

const state = {
    teams: [],
    team: null
};

const getters = {
    stateTeams: state => state.teams,
    stateTeam: state => state.team,
};

const actions = {
    getTeams({ commit }) {
        axios.get('/registrations').then((result) => {
            commit('setTeams', result.data);
        }).catch((error) => {
            console.log('error getting teams: %o', error);
        });
    },
    viewTeam({ commit }, id) {
        axios.get(`/registrations/${id}`).then((result) => {
            commit('setTeam', result.data);
        }).catch((error) => {
            console.log('error getting team: %o', error.status);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    //   async updateNote({}, note) {
    //     await axios.patch(`note/${note.id}`, note.form);
    //   },
    // eslint-disable-next-line no-empty-pattern
    //   async deleteNote({}, id) {
    //     await axios.delete(`note/${id}`);
    //   }
};

const mutations = {
    setTeams(state, teams) {
        state.teams = teams;
    },
    setTeam(state, team) {
        state.team = team;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};