<template>
  <v-app-bar :elevation="1" color="blue-grey-darken-3">
    <template v-slot:prepend>
      <v-btn
        @click="this.$router.push('/')"
        icon="mdi-home"
        variant="plain"
      ></v-btn>
    </template>

    <template v-slot:append>
      <v-menu v-if="isLoggedIn">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-menu" v-bind="props" variant="plain"> </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in this.getMappedMenue()"
            :key="index"
            @click="this.$router.push(item.route)"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <template v-slot:prepend>
              <v-icon icon="mdi-logout"></v-icon>
            </template>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
          
        </v-list>
      </v-menu>
      <v-menu v-else>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-menu" v-bind="props" variant="plain"></v-btn>
        </template>
        <v-list>
          <v-list-item @click="this.$router.push('/login')">
            <template v-slot:prepend
              ><v-icon icon="mdi-login"></v-icon
            ></template>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-app-bar-title> European Canoe Polo Championships 2023 </v-app-bar-title>
  </v-app-bar>
</template>
  
  <script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
  data() {
    return {
      drawer: false,
      group: null,
      roleMenueItems: {
        all: [
          { title: "Profil", route: "/profile", icon: "mdi-account" },
        ],
        admin: [
          { title: "Dashboard", route: "/dashboard", icon: "mdi-home" },
          { title: "Teams", route: "/teams", icon: "mdi-account-group" },
          { title: "My Teams", route: "/myTeams", icon: "mdi-account-group" },
          { title: "Camping", route: "/campers", icon: "mdi-campfire" },
          { title: "Helfer", route: "/helpers", icon: "mdi-account-supervisor" },
          // { title: "Personen", route: "/personen", icon: "mdi-account-supervisor" },

        ],
        teammanager: [
          { title: "Dashboard", route: "/dashboard", icon: "mdi-home" },
          { title: "My Teams", route: "/myTeams", icon: "mdi-account-group" },
        ],
        staff: [
          { title: "Teams", route: "/teams", icon: "mdi-account-group" },
          { title: "Camping", route: "/campers", icon: "mdi-campfire" },
          { title: "Helfer", route: "/helpers", icon: "mdi-account-supervisor" },
        ]
      },
      menueItems: [
        // {title: 'Übersicht', route: '/dashboard'},
        { title: "Teams", route: "/teams", icon: "mdi-account-group" },
        { title: "Camping", route: "/campers", icon: "mdi-campfire" },
        { title: "Helfer", route: "/helpers", icon: "mdi-account-supervisor" },
      ],
      loginItems: [
        { title: "Profil", route: "/profile", icon: "mdi-account" },
        { title: "Abmelden", route: "/logout", icon: "mdi-logout" },
      ],
      logoffItems: [
        { title: "Anmelden", route: "/login", icon: "mdi-login" }
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    }
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logOut");
      this.$router.push("/login");
    },
    getMappedMenue() {
      const _roles = this.$store.state.users.roles;
      const _menue = [];
      _roles.forEach((value) => {
        try {
          const  _items = this.roleMenueItems[value];
          _menue.push(..._items);  
        } catch(e) {
          console.log('role not there');
        }
      });
      
      _menue.push(...this.roleMenueItems['all']);
      return _menue;
    }
  },
});
</script>
  
  <style scoped>
a {
  cursor: pointer;
}
</style>