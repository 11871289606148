<template>
  <section>
    <h1>Welcome to the Canoe Polo European Championship 2023 Portal</h1>

    <div v-if="isLoggedIn" id="logout">      
      <v-btn @click="logout()" variant="tonal" color="red">
        <v-icon icon="mdi-logout" />
        abmelden
      </v-btn>
    </div>
    <p v-else>
      <span><a href="/login">Please login</a></span>
    </p>
  </section>
</template>
<script>

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  computed : {
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logOut');
      this.$router.push('/');
    }
  }
});
</script>