<template>
  <section>
    <v-card class="py-3 px-2">
      <v-card-title>Teams</v-card-title>
      <v-card-text v-if="teams">
        <v-row class="align-center">
          <v-col>
            Teams: {{ numOfTeams() }},&nbsp;&nbsp;&nbsp; 
            Anmeldungen: {{ teams.length }},&nbsp;&nbsp;&nbsp;
            
          </v-col>
          <v-col>
            Herren: {{ numOfMen }},&nbsp;&nbsp;&nbsp;
            Herren U21: {{ numOfMenU21 }},&nbsp;&nbsp;&nbsp;
            Damen: {{ numOfWomen }},&nbsp;&nbsp;&nbsp;
            Damen U21: {{ numOfWomenU21 }}      
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn @click="exportFile" color="green-lighten-1">
              Excel
              <v-icon icon="mdi-download-outline"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Suche..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-data-table
        v-model:items-per-page="items_per_page"
        :headers="headers"
        :items="updatedTeams"
        :search="search"
        density="compact" 
        :hover="true"
      >
      
      <template v-slot:[`item.email`]="{ item }">        
        <v-icon start icon="mdi-mail" @click="copyItemText('Mailadresse', item.raw.email)" />
        <v-tooltip activator="parent" location="end">
          {{ item.raw.email }}
        </v-tooltip>
      </template>
      
      <template v-slot:[`item.is_men`]="{ item }">
        <v-icon :color="`${item.raw.is_men == true ? 'green-darken-2' :'red'}`">{{ item.raw.is_men ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'}}</v-icon>        
      </template>

      <template v-slot:[`item.is_men_u21`]="{ item }">
        <v-icon :color="`${item.raw.is_men_u21 == true ? 'green-darken-2' :'red'}`">{{ item.raw.is_men_u21 ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'}}</v-icon>        
      </template>

      <template v-slot:[`item.is_women`]="{ item }">
        <v-icon :color="`${item.raw.is_women == true ? 'green-darken-2' :'red'}`">{{ item.raw.is_women ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'}}</v-icon>        
      </template>

      <template v-slot:[`item.is_women_u21`]="{ item }">
        <v-icon :color="`${item.raw.is_women_u21 == true ? 'green-darken-2' :'red'}`">{{ item.raw.is_women_u21 ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'}}</v-icon>        
      </template>

      <template v-slot:[`item.approved`]="{ item }">
        <v-icon :color="`${item.raw.approved == true ? 'green-darken-2' :'red'}`">{{ item.raw.approved ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'}}</v-icon>        
      </template>

    </v-data-table>
    </v-card>
    <v-snackbar v-model="copyHint" timeout=3000 :close-on-content-click=true color="green">
      <div class="text-center">{{ this.copyHintTitle }} kopiert</div>
    </v-snackbar>
  </section>
</template>
  
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { utils, writeFileXLSX } from "xlsx";

export default defineComponent({
  name: 'TeamsView',
  data() {
    return {
      search: '',
      items_per_page: 25,
      copyHint: false,
      copyHintTitle: '',
      headers: [
        {title: 'Team', align:'start', sortable: true, key: 'team_name'},
        {title: 'Mail', align:'start', sortable: false, key: 'email'},
        {title: 'Lead', align:'start', sortable: false, key: 'team_leader'},
        {title: 'Phone', align:'start', sortable: false, key: 'phone'},
        {title: 'Country', align:'start', sortable: false, key: 'country'},
        {title: 'Men', align:'center', sortable: false, key: 'is_men'},
        {title: 'Men21', align:'center', sortable: false, key: 'is_men_u21'},
        {title: 'Women', align:'center', sortable: false, key: 'is_women'},
        {title: 'Women21', align:'center', sortable: false, key: 'is_women_u21'},
        {title: 'Approved', align:'center', sortable: false, key: 'approved'},
      ]
    }
  },
  beforeMount: function () {
    // console.log('before mounted');
  },
  created: function () {
    this.$store.dispatch('getTeams');
    return;
  },
  computed: {
    ...mapGetters({ teams: 'stateTeams' }),
    updatedTeams() {
      return this.teams;
    },
    numOfMen() {
      let _count = 0;
      for (let i = 0; i < this.teams.length; i++) {
        _count += this.teams[i].is_men;
      }
      return _count;
    },
    numOfMenU21() {
      let _count = 0;
      for (let i = 0; i < this.teams.length; i++) {
        _count += this.teams[i].is_men_u21;
      }
      return _count;
    },
    numOfWomen() {
      let _count = 0;
      for (let i = 0; i < this.teams.length; i++) {
        _count += this.teams[i].is_women;
      }
      return _count;
    },
    numOfWomenU21() {
      let _count = 0;
      for (let i = 0; i < this.teams.length; i++) {
        _count += this.teams[i].is_women_u21;
      }
      return _count;
    },
  },
  methods: {
    //   ...mapActions(['deleteUser']),
    //   async deleteAccount() {
    //     try {
    //       await this.deleteUser(this.user.id);
    //       await this.$store.dispatch('logOut');
    //       this.$router.push('/');
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    numOfTeams() {
      return this.numOfMen + this.numOfMenU21 + this.numOfWomen + this.numOfWomenU21;
    },
    reformatTeams() {
      const result = [];
      this.updatedTeams.forEach(element => {
        result.push(
          {
            'Team': element.team_name,
            'Mail': element.email,
            'Lead': element.team_leader,
            'Phone': element.phone,
            'Country': element.country,
            'Men': element.is_men ? 'Ja':'Nein',
            'Men21': element.is_men_u21 ? 'Ja':'Nein',
            'Women': element.is_women ? 'Ja':'Nein',
            'Women21': element.is_women_u21 ? 'Ja':'Nein',
            'Approved': element.approved ? 'Ja':'Nein'
          }
        )
      });
      return result;
    },
    getMaxWidth(itemName) {
      let maxWidth = 0;
      const _teams = this.reformatTeams();
      for(let i =0; i < _teams.length; i++) {
        if(
          _teams[i][itemName].length >= maxWidth
        ) {
          maxWidth = _teams[i][itemName].length;
        }
      }
      return maxWidth 
    },
    exportFile() {
      const rows = this.reformatTeams();
      const ws = utils.json_to_sheet(rows);
      const wb = utils.book_new();
      const _date = (moment()).format("YYYYMMDD");
      
      ws['!cols'] = [
        {'wch': this.getMaxWidth('Team')},
        {'wch': this.getMaxWidth('Mail')},     
        {'wch': this.getMaxWidth('Lead')},    
        {'wch': this.getMaxWidth('Phone')}        
      ];
      console.log(ws);
      utils.book_append_sheet(wb, ws, "Data");

      writeFileXLSX(wb, `${_date}-teams.xlsx`);
    },
    copyItemText(title, itemText) {
      this.copyHintTitle = title + ' "' + itemText + '" ';
      navigator.clipboard.writeText(itemText);
      this.copyHint = true;
    },
  },
});
</script>
<style scoped>
  .mail {
    text-decoration: none;
  }
</style>