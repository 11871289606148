<template>

<section v-if="helpers">
  <v-card class="py-3 px-2">
    <v-card-title>
      Registrierte Helfer
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <ul class="sizeList">
            <li class="sizeEntry">Shirt Größen: </li>
            <li class="sizeEntry" v-for="(size, index) in shirtsMatrix" :key="size" >{{ index }}: {{ size }}&nbsp;</li>
          </ul>
          <ul class="sizeList">
            <li class="sizeEntry">Anmeldungen: {{ helpers.length }}</li>
            <li class="sizeEntry" v-for="(hotel, index) in accomodations" :key="hotel" >
              {{ index == 'self' ? 'Selbstversorger' : 'Camping' }}: {{ hotel }}&nbsp;
            </li>
          </ul>
        </v-col>
        <v-col cols="3" class="text-right">
            <v-btn @click="exportFile" color="green-lighten-1">
              Export
              <v-icon icon="mdi-download-outline"></v-icon>
            </v-btn>
          </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-text-field
        v-model="search"
        label="Suche..."
        single-line
        hide-details
      ></v-text-field>
    </v-card-text>
    <v-data-table
          v-model:items-per-page="items_per_page"
          :headers="headers"
          :items="helpers"
          :search="search"
          density="compact" 
          :hover="true"
        >

        <template v-slot:[`item.email`]="{ item }">        
          <v-icon start icon="mdi-mail" @click="copyItemText('Mailadresse', item.raw.email)" />
          <v-tooltip activator="parent" location="end">
            {{ item.raw.email }}
          </v-tooltip>
        </template>
      
        <template v-slot:[`item.date_of_birth`]="{ item }">{{ formatedDate(item.raw.date_of_birth) }}</template>

        <template v-slot:[`item.arival_date`]="{ item }">{{ formatedDate(item.raw.arival_date) }}</template>
        
        <template v-slot:[`item.departure_date`]="{ item }">{{ formatedDate(item.raw.departure_date) }}</template>
        
        <template v-slot:[`item.qualification`]="{ item }">
          {{ item.raw.qualification !== 'None' ? item.raw.qualification : '' }}
        </template>

        <template v-slot:[`item.approved`]="{ item }">
          <v-icon :color="`${item.raw.approved == true ? 'green-darken-2' :'red'}`">{{ item.raw.approved ? 'mdi-check-circle-outline': 'mdi-alert-circle-outline' }}</v-icon>
          <v-tooltip activator="parent" location="end">{{ item.raw.time_updated ? item.raw.time_updated : item.raw.time_created }}</v-tooltip>  
        </template>

        <template v-slot:[`item.accomodation`]="{ item }">
          {{ item.raw.accomodation == 'self' ? 'Hotel' : 'Camping' }}
        </template>

        <template v-slot:[`item.photo_id`]="{ item }">
          <v-img 
            class="mx-auto my-1"
            height="70"
            max-height="70"
            :src="`/upload/${item.raw.photo_id}`"
            @click="this.dialog = true; this.selectedHelper = item.raw"
          >
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
              </div>
            </template>
          </v-img>
        </template>

    </v-data-table>
  </v-card>
  
  <v-snackbar v-model="copyHint" timeout=3000 :close-on-content-click="true" color="green">
    <div class="text-center">{{ this.copyHintTitle }} kopiert</div>
  </v-snackbar>

  <v-dialog v-model="dialog" class="mx-9 my-9">
    <v-card>
      <v-card-text>
        {{ this.selectedHelper.full_name }}
        <v-img 
            class="mx-auto my-1"
            height="400"
            :src="`/upload/${this.selectedHelper.photo_id}`"
          />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="this.dialog = false">Close Dialog</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</section>
</template>
  
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { utils, writeFileXLSX } from "xlsx";

export default defineComponent({
  name: 'HelpersView',
  data() {
    return {
      dialog: false,
      selectedHelper: null,
      copyHint: false,
      copyHintTitle: '',
      items_per_page: 25,
      headers: [
        { title: 'Name', align: 'start', sortable: false, key: 'full_name',},
        { title: 'Mail', align: 'center', sortable: false, key: 'email' },
        { title: 'Geb', align: 'center', sortable: false, key: 'date_of_birth' },
        { title: 'Quali', align: 'center', sortable: false, key: 'qualification' },
        { title: 'Ankunft', align: 'center', sortable: false, key: 'arival_date' },
        { title: 'Abreise', align: 'center', sortable: false, key: 'departure_date' },
        { title: 'Quartier', align: 'center', sortable: false, key: 'accomodation' },
        { title: 'Essen', align: 'center', sortable: false, key: 'food' },
        { title: 'Shirt', align: 'center', sortable: false, key: 'shirt_size' },
        { title: 'Foto', align: 'center', sortable: false, key: 'photo_id' },
        { title: 'Bestätigt', align: 'center', sortable: false, key: 'approved' },
          
      ],
      search: ''
    }
  },
  created: function () {
    this.$store.dispatch('getHelpers');
    return;
  },
  computed: {
    ...mapGetters({ helpers: 'stateHelpers' }),
    formatedDate() {
      return (date) => {
        const _date = moment(date);
        return _date.format('DD.MM.YY')
      }
    },
    getNumOfX() {
      return 1;
    },
    shirtsMatrix() {
      const _result = {
        'XS': 0, 'S': 0, 'M': 0, 
        'L': 0, 'XL': 0, 'XXL': 0, 
        'XXXL': 0, 'XXXXL': 0, 'XXXXXL': 0
      };
      for(let i=0; i < this.helpers.length; i++) {
        const _helper = this.helpers[i];
        _result[_helper.shirt_size] += 1;
      }
      return _result;
    },
    accomodations() {
      const _result = {
        'self': 0,
        'campsite': 0
      }

      for(let i=0; i < this.helpers.length; i++) {
        const _helper = this.helpers[i];
        _result[_helper.accomodation] += 1;
      }
      return _result;
    },
  },
  methods: {
    copyItemText(title, itemText) {
      this.copyHintTitle = title + ' "' + itemText + '" ';
      navigator.clipboard.writeText(itemText);
      this.copyHint = true;
    },
    getMaxWidth(itemName) {
      let maxWidth = 0;
      for(let i =0; i < this.helpers.length; i++) {
        if(
          this.helpers[i][itemName].length >= maxWidth
        ) {
          maxWidth = this.helpers[i][itemName].length;
        }
      }
      return maxWidth 
    },
    reformatHelpers() {
      const result = [];
      this.helpers.forEach(element => {
        result.push(
          {
            'Name': element.full_name,
            'Mail': element.email,
            'Geb': (moment(element.date_of_birth)).format('DD.MM.YYYY'),
            'Quali': element.qualification == 'None' ? '': element.qualification, 
            'Ankunft': (moment(element.arival_date)).format('DD.MM.YYYY'), 
            'Abreise': (moment(element.departure_date)).format('DD.MM.YYYY'), 
            'Quartier': element.accomodation == 'self' ? 'Hotel': 'Camping', 
            'Essen': element.food, 
            'Shirt': element.shirt_size, 
            'Bestätigt': element.approved ? 'Ja':'Nein'
          }
        )
      });
      return result;
    },
    exportFile() {
      const rows = this.reformatHelpers();
      const ws = utils.json_to_sheet(rows);
      const wb = utils.book_new();
      
      ws['!cols'] = [
        {'width': this.getMaxWidth('full_name')},
        {'width': this.getMaxWidth('email')}        
      ];
      console.log(ws);
      
      utils.book_append_sheet(wb, ws, "Data");
      const _date = (moment()).format("YYYYMMDD");
      writeFileXLSX(wb, `${_date}-helfer.xlsx`);
    }
  },
});
</script>
<style scoped lang="scss">
  .mail {
    text-decoration: none;
  }

  ul.sizeList {
    list-style: none;
    margin: 0;
    padding: 0;
    li.sizeEntry {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  .helpersPhoto {
    max-height: 50px;
  }
</style>