import axios from 'axios';

const state = {
    campers: [],
    camper: null
};

const getters = {
    stateCampers: state => state.campers,
    stateCamper: state => state.camper,
};

const actions = {
    //   async createNote({dispatch}, note) {
    //     await axios.post('notes', note);
    //     await dispatch('getNotes');
    //   },
    getCampers({ commit }) {
        axios.get('/camper').then((result) => {
            commit('setCampers', result.data);
        }).catch((error) => {
            console.log('error getting campers: %o', error);
        });
    },
    viewCamper({ commit }, id) {
        axios.get(`/camper/${id}`).then((result) => {
            commit('setCamper', result.data);
        }).catch((error) => {
            console.log('error getting camper: %o', error.status);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    //   async updateNote({}, note) {
    //     await axios.patch(`note/${note.id}`, note.form);
    //   },
    // eslint-disable-next-line no-empty-pattern
    //   async deleteNote({}, id) {
    //     await axios.delete(`note/${id}`);
    //   }
};

const mutations = {
    setCampers(state, campers) {
        // console.log('set campers mutation %o', campers);
        state.campers = campers;
    },
    setCamper(state, camper) {
        state.camper = camper;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};