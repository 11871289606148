<template>
  <v-app id="app" class="bg-blue-grey-lighten-5">
    <NavBar />
    <v-main>
      <v-container :fluid="true">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
export default {
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.main {
  padding-top: 5em;
}
</style>