<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8" xl="6">
        <v-sheet class="px-6 py-6">
          <v-card class="mx-auto px-6 py-8" :elevation="0">
            <v-card-title>Login</v-card-title>
            <v-form @submit.prevent class="pt-3">
              <v-text-field ref='nameField' v-model="username" label="Username" required
                v-on:keyup.enter="onEnter()"></v-text-field>
              <v-text-field ref="passwordField" type="password" v-model="password" label="Password" required
                v-on:keyup.enter="onEnter()"></v-text-field>
              <v-btn color="blue-grey-darken-3" block @click="submit()">Login</v-btn>
            </v-form>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>  
<script>

import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'LoginView',
  data() {
    return {
      valid: false,
      username: '',
      password: ''
    };
  },
  methods: {
    ...mapActions(['logIn']),
    submit() {
      const _user = {
        'username': this.username,
        'password': this.password
      }
      this.logIn(_user).then(
        () => {
          this.$router.push('/dashboard');
        }
      ).catch(() => {
        this.$router.push('/login');
      });
    },
    onEnter() {
      if (this.username.length > 0 && this.password.length > 0) {
        this.submit();
        return;
      }

      if (this.username.length > 0) {
        this.$refs.passwordField.focus();
      }

      if (this.password.length > 0) {
        this.$refs.nameField.focus();
      }
    }
  }
});
</script>