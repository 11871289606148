<template>
    <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-3" align="end">
            <span :class="index+1 < 10 ? 'pl-2': ''">{{ index+1 }}.</span>
           <v-icon class="ml-2" :icon="intPerson.role == 'player' ? 'mdi-kayaking' : 'mdi-account'"></v-icon>
        </v-col>
        <v-col>
            <v-text-field label="Firstname" v-model="intPerson.first_name" @input="emitData" hide-details="auto" :disabled="isDisabled">
            </v-text-field>
        </v-col>
        <v-col  class="mx-2">
            <v-text-field label="Lastname" v-model="intPerson.last_name" @input="emitData" hide-details="auto"  :disabled="isDisabled"></v-text-field>
        </v-col>
        <v-col>
            <v-text-field label="Date of birth" type="date" min="1960-01-01" max="2010-01-01" @input="emitData"  :disabled="isDisabled" v-model="intPerson.date_of_birth" hide-details="auto"></v-text-field>
        </v-col>
        <v-col class="pl-2" cols="auto">
            <v-row align="center">
                <v-col cols="auto">
                    <div style="position: relative; display:inline-block;" @mouseover="hover = true" @mouseleave="hover = false">
                        <v-img v-if="!this.hasImageData && imageData === null" :src="`/teams/${intPerson.photo_id}`"  class="passImg"  :disabled="isDisabled" >
                            <template v-slot:placeholder>
                                <div class="d-flex align-center justify-center fill-height">
                                    <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
                                </div>
                            </template>
                        </v-img>
                        <v-img  v-else class="passImg" :src="imageData"  style="border:solid 0px grey-lighten-4;"  :disabled="isDisabled">
                            <template v-slot:placeholder>
                                <div class="d-flex align-center justify-center fill-height">
                                    <v-icon :disabled="isDisabled" icon="mdi-image-plus-outline"></v-icon>
                                    <!-- <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular> -->
                                </div>
                            </template>
                        </v-img>
                        <v-btn  :disabled="isDisabled" :class="{ active: hover, addButton: true }" elevation="1" color="success"  density="default" size="x-small" icon="mdi-image-edit-outline" @click="chooseImage" ></v-btn>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <v-btn :disabled="isDisabled" variant="tonal" @click="savePlayer()" :color="isDirty ? 'error': 'success'"><v-icon icon="mdi-content-save" class="mr-2" ></v-icon>Save</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <input class="file-input" ref="fileInput" type="file" @input="onSelectFile" accept="image/png, image/gif, image/jpeg" />
        <v-dialog v-model="dialog" :scrim="false" persistent width="auto" scroll-strategy="none" >
            <v-card color="light">
                <v-card-text>Saving...
                    <v-progress-linear indeterminate color="success" class="mb-0" ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

import { defineComponent } from "vue";
import moment from 'moment';

export default defineComponent({
    name: "PersonComponent",
    props: [
        'person', 'index', 'disabled'
    ],
    expose: [
        'savePlayer', 'isDirty'
    ],
    data() {
        return {
            isDisabled: this.disabled,
            dialog: false,
            hover: false,
            personTypes: [
                { rawTitle: 'Player', rawVal: 'player' },
                { rawTitle: 'Other', rawVal: 'other' }
            ],
            editable: false,
            intPerson: this.person,
            currentImage: undefined,
            previewImage: undefined,
            imageData: null,
            isDirty: false,
            teamNumberRules: [
                value => {
                    if(
                        value?.length >= 1 
                        && value?.length <= 2
                    ) {
                        return true;
                    }
                    

                    return 'Number must between 1 and 99'
                },
            ],
            full_nameRules: [
                value => {
                    if(value?.length >= 3) {
                        return true;
                    }
                    return 'enter at least 3 characters';
                }
            ],
            date_of_birthRules: [
                value => {
                    // console.log('value of date: %O', value);
                    const _checkDate = moment(value);
                    if(_checkDate.isValid()) {
                        return true;
                    }
                    return 'Enter valid date';
                }
            ],
        }
    },
    watch: {
    //   dialog (val) {
    //     if (!val) return

    //     setTimeout(() => (this.dialog = false), 3000)
    //   },
    },
    computed: {
        hasImageData() {
            return this.intPerson.photo_id == null || this.intPerson.photo_id == 'null'
        }
    },
    methods: {
        chooseImage() {
            this.$refs.fileInput.click();
        }
        ,
        onSelectFile() {
            const input = this.$refs.fileInput
            const files = input.files
            if (files && files[0]) {
                const reader = new FileReader;
                reader.onload = e => {
                    this.imageData = e.target.result
                }
                reader.readAsDataURL(files[0])
                this.$emit('input', files[0])

                this.isDirty=true;
            }
        },
        emitData() {
            this.isDirty = true;
            // console.log('log data: emit data: %O', this.person);
            // console.log('log data: emit int person data: %O', this.intPerson);
            this.$emit('update:data', this.intPerson);
        },
        savePlayer() {
            this.dialog = true;
            let photo_bytes = null

            const input = this.$refs.fileInput
            const files = input.files
            
            if (files && files[0]) {
                photo_bytes = files[0]
            }
            
            this.$store.dispatch('updateTeamMember', {'team_id': this.intPerson.team_id, 'member': this.intPerson, 'photo_bytes': photo_bytes})
            .then((result) => {
                console.log('saved player: %O', result);
                if(result.data.status === 'okay') {
                    this.intPerson.photo_id = result.data.photo_id;
                    this.isDirty = false;
                } 
                this.dialog = false;
            }).catch((error) => {
                this.dialog = false;
                console.log('saved player error: %O', error);
            }).finally(() => {
                this.dialog = false;
            });
            
        }
    }
});
</script>

<style scoped lang="scss">


.passImg {
    aspect-ratio: 35/45;
    border: solid 1px grey;
    max-height: 55px;
    height: 55px;
    border-radius: 5px;
}

.addButton {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
        display: block;
    }
}

.file-input {
    display: none;
}

</style>