import axios from 'axios';

const state = {
    myTeams: [],
    myCurrentTeam: null
};

const getters = {
    stateMyTeams: state => state.myTeams,
    stateMyCurrentTeam: state => state.myCurrentTeam,
};

const actions = {
    getMyTeams({ commit }) {
        axios.get('/myteams').then((result) => {
            commit('setMyTeams', result.data);
        }).catch((error) => {
            console.log('error getting teams: %o', error);
        });
    },
    viewMyTeam({ commit }, id) {
        axios.get(`/myteams/${id}`).then((result) => {
            commit('setMyCurrentTeam', result.data);
        }).catch((error) => {
            console.log('error getting team: %o', error.status);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    updateMyTeam({ }, team) {
        axios.patch(`/myteams`, team).then(()=> {
            // console.log('result: ', result.data);
        }).catch((error) => {
            console.log('error updating team: %o', error);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    updateTeamMember({ }, {team_id, member, photo_bytes}) {
        let _data = new FormData();

        if(photo_bytes !== null) {
            _data.append('photo', photo_bytes);
        } else {
            // _data.append('photo', null);
        }

        _data.append('id', member.id);
        _data.append('first_name', member.first_name);
        _data.append('last_name', member.last_name);
        _data.append('date_of_birth', member.date_of_birth);
        _data.append('role', member.role);
        _data.append('team_id', member.team_id);
        _data.append('team_member', member);
        _data.append('photo_id', member.photo_id);
        
        let _url = "/myteams/" + team_id + "/" + member.id; // + team_id + "/" + member.id;

        return axios({
            method: 'PATCH',
            url: _url,
            data: _data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    // loadPerson( { commit }, id) {
    //     axios
    //         .get(`/`)
    //         .then()
    //         .catch(error => {
    //             console.log('error getting person');
    //     });
    // }
    
    // eslint-disable-next-line no-empty-pattern
    //   async deleteNote({}, id) {
    //     await axios.delete(`note/${id}`);
    //   }
};

const mutations = {
    setMyTeams(state, teams) {
        state.myTeams = teams;
    },
    setMyCurrentTeam(state, team) {
        state.myCurrentTeam = team;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};