<template>
  <section>
    <v-card class="py-3 px-2">
      <v-card-title>
        Registrierte Camper
      </v-card-title>
      <v-card-text>
        <v-row class="align-center">
          <v-col>
            Anmeldungen: {{ campers.length }},&nbsp;&nbsp;
            Personen: {{ getNumOfPeople }},&nbsp;&nbsp;
            Fahrzeuge: {{ getNumOfVehicles }},&nbsp;&nbsp;
            Zelte: {{ getNumOfTents }},&nbsp;&nbsp;
            Zelte Groß: {{ getNumOfBigTents }}
          </v-col>
          <v-col cols="3" class="justify-end text-right">
            <v-btn @click="exportFile" color="green-lighten-1">
              Excel
              <v-icon icon="mdi-download-outline"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Suche..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-text>
      
      <v-data-table
        v-model:items-per-page="items_per_page"
        :headers="headers"
        :items="updatedCampers"
        :search="search"
        density="compact" 
        :hover="true"
      >

      <template v-slot:[`item.full_name`]="{item}">
        <span @click="copyLine(item.raw)">{{item.raw.full_name}}</span>
      </template>

      <template v-slot:[`item.email`]="{ item }">        
          <v-icon start icon="mdi-mail" @click="copyItemText('Mailadresse', item.raw.email)" />
          <v-tooltip activator="parent" location="end">
          {{ item.raw.email }}
        </v-tooltip>
      </template>

      <template v-slot:[`item.arival_date`]="{ item }">{{ formatedDate(item.raw.arival_date) }}</template>
      <template v-slot:[`item.departure_date`]="{ item }">{{ formatedDate(item.raw.departure_date) }}</template>

      <template v-slot:[`item.approved`]="{ item }">
        <v-icon :color="`${item.raw.approved == true ? 'green-darken-2' :'red'}`">{{ item.raw.approved ? 'mdi-check-circle-outline': 'mdi-alert-circle-outline' }}</v-icon>
        <v-tooltip activator="parent" location="end" >
          {{ item.raw.approved ? formatedDate(item.raw.time_updated) : 'noch nicht bestätigt' }}
        </v-tooltip>
      </template>
      
      <template #[`item.paymentRef`]="{ item }">
        <v-icon :color="`${item.raw.payment_received == true ? 'green-darken-2' :'red'}`" @click="copyItemText('Referenz', paymentRef(item.raw))">
          {{ item.raw.payment_received ? 'mdi-check-circle-outline': 'mdi-alert-circle-outline' }}
        </v-icon>
        <v-tooltip activator="parent" location="end">{{ paymentRef(item.raw) }}</v-tooltip>  
      </template>
  
      <template #[`item.paymentReference`]="{ item }">
        {{ paymentRef(item.raw) }}
      </template>

        <template #[`item.actions`]="{ item }">
          <v-btn icon="mdi-pen" class="mx-3" variant="text" density="compact" color="green" @click="editItem(item)"></v-btn>
          <!-- <v-icon icon="mdi-pen" class="mx-3" @click="actionItem(item.raw)"></v-icon>
          <v-icon icon="mdi-delete-forever" @click="actionItem(item.raw)"></v-icon> -->
        </template>
        
      </v-data-table>
    </v-card>

    <v-snackbar v-model="copyHint" timeout=3000 :close-on-content-click=true color="green">
      <div class="text-center">{{ this.copyHintTitle }} kopiert</div>
    </v-snackbar>
  </section>
</template>
  
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import 'bootstrap';
import { utils, writeFileXLSX } from "xlsx";

export default defineComponent({
  name: 'CampersView',
  data() {
    return {
      search: '',
      modal_demo: null,
      items_per_page: 50,
      copyHint: false,
      copyHintTitle: '',
      headers: [
          { title: 'Name', align: 'start', sortable: false, key: 'full_name',},
          { title: 'Mail', align: 'center', sortable: false, key: 'email' },
          { title: 'Pers.', align: 'center', sortable: false, key: 'num_of_persons' },
          { title: 'Autos', align: 'center', sortable: false, key: 'num_of_vehicles' },
          { title: 'Zelte', align: 'center', sortable: false, key: 'num_of_tents' },
          { title: 'Zelte (G)', align: 'center', sortable: false, key: 'num_of_big_tents' },
          { title: 'Ankunft', align: 'center', sortable: false, key: 'arival_date' },
          { title: 'Abreise', align: 'center', sortable: false, key: 'departure_date' },
          { title: 'Bestätigt', align: 'center', sortable: false, key: 'approved' },
          // { title: 'Ref', align: 'end', key: 'payment_received' },
          { title: 'Bezahlt', align: 'center', sortable: false, key: 'paymentRef'},
          // { title: 'Edit', align: 'center', key: 'actions', sortable: false },
        ]
    }
  },
  beforeMount: function () {
  },
  onMounted: function() {
  },
  created: function () {
    this.$store.dispatch('getCampers');
    return;
  },
  computed: {
    ...mapGetters({ campers: 'stateCampers' }),
    updatedCampers() {
      return this.campers.map((item) => { return item = { ...item, 'paymentRef': this.paymentRef(item)}});
    },
    paymentRef() {
      return (camper) => {
        // return reference from YYYYMMMDDD-ID where the registration is created
        const _id = camper.id
        const _date = moment(camper.time_created);
        return _date.format('YYYYMMDD') + '-' + _id;
      }
    },
    formatedDate() {
      return (date) => {
        const _date = moment(date);
        return _date.format('DD.MM.YY')
      }
    },
    getNumOfPeople() {
      let _count = 0;
      for (let i = 0; i < this.campers.length; i++) {
        _count += this.campers[i].num_of_persons;
      }
      return _count;
    },
    getNumOfVehicles() {
      let _count = 0;
      for (let i = 0; i < this.campers.length; i++) {
        _count += this.campers[i].num_of_vehicles;
      }
      return _count;
    },
    getNumOfTents() {
      let _count = 0;
      for (let i = 0; i < this.campers.length; i++) {
        _count += this.campers[i].num_of_tents;
      }
      return _count;
    },
    getNumOfBigTents() {
      let _count = 0;
      for (let i = 0; i < this.campers.length; i++) {
        _count += this.campers[i].num_of_big_tents;
      }
      return _count;
    }
  },
  methods: {
    //   ...mapActions(['deleteUser']),
    //   async deleteAccount() {
    //     try {
    //       await this.deleteUser(this.user.id);
    //       await this.$store.dispatch('logOut');
    //       this.$router.push('/');
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    get_ref_string(date) {
      const _date = Date.parse(date);
      const _d = new Date(_date);
      return _d.getFullYear() + '' + _d.getMonth() + '';
    },
    editItem(item) {
      console.log('item: %O', item);
    },    
    copyItemText(title, itemText) {
      this.copyHintTitle = title + ' "' + itemText + '" ';
      navigator.clipboard.writeText(itemText);
      this.copyHint = true;
    },
    reformatCampers() {
      const result = [];
      this.updatedCampers.forEach(element => {
        const _id = element.id;
        const _date = moment(element.time_created);
        const _ref = _date.format('YYYYMMDD') + '-' + _id;
        const _sum = (element.num_of_persons * 25) + (element.num_of_big_tents * 100)
           + (element.num_of_tents * 50) + (element.num_of_vehicles * 100)
        result.push(
          {
            'Name': element.full_name,
            'E-Mail': element.email,
            'Personen': element.num_of_persons,
            'Autos': element.num_of_vehicles,
            'Zelte': element.num_of_tents,
            'Zelte Groß': element.num_of_big_tents,
            'Ankunft': (moment(element.arival_date)).format('DD.MM.YYYY'),
            'Abreise': (moment(element.departure_date)).format('DD.MM.YYYY'),
            'Bestätigt': element.approved ? 'Ja':'Nein',
            'Bezahlt': element.payment_received ? 'Ja': 'Nein',
            'Betrag': _sum,
            'Referenz': _ref
          }
        )
      });

      return result;
    },
    copyLine(item) {
      const _id = item.id;
        const _date = moment(item.time_created);
        const _ref = _date.format('YYYYMMDD') + '-' + _id;
        const _sum = (item.num_of_persons * 25) + (item.num_of_big_tents * 100)
           + (item.num_of_tents * 50) + (item.num_of_vehicles * 100);
      const _copyString = `${item.full_name}\t${item.email}\t${item.num_of_persons}\t${item.num_of_vehicles}\t${item.num_of_tents}\t${item.num_of_big_tents}\t${(moment(item.arival_date)).format('DD.MM.YYYY')}\t${(moment(item.departure_date)).format('DD.MM.YYYY')}\t${item.approved ? 'Ja':'Nein'}\t${item.payment_received ? 'Ja': 'Nein'}\t${_sum}\t${_ref}`;
      navigator.clipboard.writeText(_copyString);
      this.copyHintTitle = `Zeile ${item.full_name} Kopiert`;
      this.copyHint = true;
    },
    getMaxWidth(itemName) {
      let maxWidth = 0;
      for(let i =0; i < this.updatedCampers.length; i++) {
        if(
          this.updatedCampers[i][itemName].length >= maxWidth
        ) {
          maxWidth = this.updatedCampers[i][itemName].length;
        }
      }
      return maxWidth 
    },
    exportFile() {
      const rows = this.reformatCampers();
      const ws = utils.json_to_sheet(rows);
      const wb = utils.book_new();
      const _format = "#.##00 €"

      ws['!cols'] = [
        {'width': this.getMaxWidth('full_name')},
        {'width': this.getMaxWidth('email')}        
      ];
      
      utils.book_append_sheet(wb, ws, "Data");
      this.formatColumn(ws, 10 , _format);
      
      const _date = (moment()).format("YYYYMMDD");
      writeFileXLSX(wb, `${_date}-campers.xlsx`);
    },
    formatColumn(worksheet, col, fmt) {
      const range = utils.decode_range(worksheet['!ref'])
      // note: range.s.r + 1 skips the header row
      for (let row = range.s.r + 1; row <= range.e.r; ++row) {
        const ref = utils.encode_cell({ r: row, c: col })
        if (worksheet[ref] && worksheet[ref].t === 'n') {
          worksheet[ref].z = fmt
        }
      }
    }
  },
});
</script>
<style scoped>
  .mail {
    text-decoration: none;
  }
</style>