
<template>
  <v-sheet class="pa-6 ">
      <div v-for="(person, index) in persons" :key="person.id">
        <PersonComponent :person="person" :index="index"  />
      </div>
  </v-sheet>
</template>
  
  <script >
  import PersonComponent from '@/components/Person.vue'
  import { defineComponent } from 'vue';
//   import { mapGetters, mapActions } from 'vuex';
  import { mapGetters } from 'vuex';
//   import store from '../store/index'
  
  export default defineComponent({
    name: 'PersonView',
    components: {
      PersonComponent
    },
    data() {
        return {
            persons: [
              { id: 1, first_name: 'Max', last_name: 'Mustermann', date_of_birth: "2000-01-01", team_number: 1, type: 'player'},
              { id: 2, first_name: 'Max1', last_name: 'Mustermann2', date_of_birth: "2000-01-02", team_number: 1, type: 'player'},
              { id: 3, first_name: 'Max2', last_name: 'Mustermann3', date_of_birth: "2000-01-03", team_number: 1, type: 'other'},
            ]
        }
    },
    computed: {
        ...mapGetters({user: 'stateUser'})
    },
    methods: {
    //   ...mapActions(['deleteUser']),
    //   async deleteAccount() {
    //     try {
    //       await this.deleteUser(this.user.id);
    //       await this.$store.dispatch('logOut');
    //       this.$router.push('/');
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    },
  });
  </script>