import axios from 'axios';
import store from '@/store'; // New
import router from '@/router/index';

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://127.0.0.1:8000/';  // the FastAPI backend
axios.defaults.baseURL = process.env.VUE_APP_API_URL;  // the FastAPI backend

axios.interceptors.request.use(async config => {    
    const _sessionToken = sessionStorage.getItem('userToken');
    // console.log('_session token %o', _sessionToken);
    if(_sessionToken !== null) {
        config.headers.Authorization = `Bearer ${_sessionToken}`
    }
    return config;
});


axios.interceptors.response.use(undefined, (error) => {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            store.dispatch('logOut');
            return router.push('/login')
        }
    }
});