<template>
    <div>
        <v-sheet class="bg-transparent">
            <v-row justify="center">
                <v-col cols="8">

                    <v-card>
                        <v-card-title>Introduction</v-card-title>
                        <v-card-text>
                            <p>Welcome to the registration portal for the European Polo Championship 2023</p>
                            <p>Here you can see your registered teams and start the numerical registration.
                                You can specify how many competitiors and accompanying person will be arriving and what kind
                                of food you would like to eat.</p>
                        </v-card-text>
                        <v-card-text>
                            <p>Since this is a new platform, here are some tips on how to enter the data correctly.</p>
                            <p>And if you have any questions, please do not hesitate to contact us at <a href="mailto:info@poloeca2023.org">info@poloeca2023.org</a></p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="8">
                    <v-card>
                        <v-card-title>How-To</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <p>Start the numerical registration</p>
                            
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-dialog persistent width="70%">
                                        <template v-slot:activator="{ props }">              
                                            <v-img src="/docs/01.jpg"  v-bind="props" class="cur"></v-img>
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            <v-card>
                                                <v-card-title class="justify-end" align="end">
                                                    <v-spacer>
                                                    </v-spacer>
                                                    <v-btn variant="plain" icon @click="isActive.value = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                </v-card-title>
                                                <v-card-item class="pb-3">
                                                    <v-img src="/docs/01.jpg" @click="isActive.value = false"></v-img>
                                                </v-card-item>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="4">
                                    <p>On the upper right side is the main menue located. Here you can navigate to the "My Teams"
                                section. All teams you have registered are shown on this page. There is a single card
                                for each team class. If you can not see all registered teams please scroll down.</p>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    <p>You can start to work on the team and determine the players and accompanying person with a
                                click at the "Edit" button. There is a maximum of ten for the number of competitors. Just
                                drag the slide to the left or right to choose. There are also buttons on the left and right
                                side to increase or decrease the number. By changing the number of person there is a live
                                preview of the participation fee.</p>
                                    <p>Pressing the save button will save the number of person. After that you can go to the food
                                selection by clicking on the "Choose Food" button.</p>
                                </v-col>
                                
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-dialog persistent width="70%">
                                        <template v-slot:activator="{ props }">              
                                            <v-img src="/docs/02.jpg"  v-bind="props" class="cur"></v-img>
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            <v-card>
                                                <v-card-title class="justify-end" align="end">
                                                    <v-spacer>
                                                    </v-spacer>
                                                    <v-btn variant="plain" icon @click="isActive.value = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                </v-card-title>
                                                <v-card-item class="pb-3">
                                                    <v-img src="/docs/02.jpg" @click="isActive.value = false"></v-img>
                                                </v-card-item>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                                <v-col>
                                    <v-dialog persistent width="70%">
                                        <template v-slot:activator="{ props }">              
                                            <v-img src="/docs/03.jpg"  v-bind="props" class="cur"></v-img>
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            <v-card>
                                                <v-card-title class="justify-end" align="end">
                                                    <v-spacer>
                                                    </v-spacer>
                                                    <v-btn variant="plain" icon @click="isActive.value = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                </v-card-title>
                                                <v-card-item class="pb-3">
                                                    <v-img src="/docs/03.jpg" @click="isActive.value = false"></v-img>
                                                </v-card-item>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    <v-dialog persistent width="70%">
                                        <template v-slot:activator="{ props }">              
                                            <v-img src="/docs/04.jpg"  v-bind="props" class="cur"></v-img>
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            <v-card>
                                                <v-card-title class="justify-end" align="end">
                                                    <v-spacer>
                                                    </v-spacer>
                                                    <v-btn variant="plain" icon @click="isActive.value = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                </v-card-title>
                                                <v-card-item class="pb-3">
                                                    <v-img src="/docs/04.jpg" @click="isActive.value = false"></v-img>
                                                </v-card-item>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                                <v-col>
                                    <p>In this screen you can select the amount of food per day. Each row contains one day and the
                                columns select the number of meals for breakfast, lunch, dinner or vegetarian meals. Select
                                the number of meals you need. At the end of each row are quick selection buttons for quick
                                selection of meals for all or no participants. Below the last row, in the columns, are quick
                                selection buttons for all, none or to increase/decrease the number of participants for that
                                particular meal.</p>
                                <p>By saving the food selection you can go to the next team and start selecting the number of
                                persons and meals. After the selection has been made for all teams, the numerical message
                                can be completed by clicking on the "Finalize Numerical Registration" button. All teams,
                                persons and meals will be presented on the next overview. </p>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            
                            <v-row>
                                <v-col>
                                    <v-dialog persistent width="70%" transition="none">
                                        <template v-slot:activator="{ props }">              
                                            <v-img src="/docs/05.jpg"  v-bind="props"></v-img>
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            <v-card>
                                                <v-card-title class="justify-end" align="end">
                                                    <v-spacer>
                                                    </v-spacer>
                                                    <v-btn variant="plain" icon @click="isActive.value = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                </v-card-title>
                                                <v-card-item class="pb-3">
                                                    <v-img src="/docs/05.jpg" @click="isActive.value = false"></v-img>
                                                </v-card-item>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                                <v-col>
                                    <v-dialog persistent width="70%" transition="none">
                                        <template v-slot:activator="{ props }">              
                                            <v-img src="/docs/07.jpg"  v-bind="props" class="preview"></v-img>
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            <v-card>
                                                <v-card-title class="justify-end" align="end">
                                                    <v-spacer>
                                                    </v-spacer>
                                                    <v-btn variant="plain" icon @click="isActive.value = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                </v-card-title>
                                                <v-card-item class="pb-3">
                                                    <v-img src="/docs/07.jpg" @click="isActive.value = false"></v-img>
                                                </v-card-item>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <p>
                                        By submitting the data, the fees for participation and catering will be due. 
                                        
                                    </p>
                                    <p>
                        <b>Credit Institut</b>: MBS Potsdam
                      <br />
                      <b>Owner: KCB e.V. represented by</b>: Michael Gußer
                      <br />
                      <b>IBAN</b>: DE19 1605 0000 1000 6407 67
                      <br />
                      <b>BIC</b>: WELAD1PMB
                    </p>
                    <p>If you have any questions, please do not hesitate to contact us at
                    <a href="mailto:info@poloeca2023.org">info@poloeca2023.org</a>
                    </p>
                                </v-col>
                            </v-row>

                            
                            <!-- finalize registration and submit -->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <!-- <h2>Camper: {{ campers.length }}</h2> -->
            <!-- <h2>Helpers: {{ helpers.length }}</h2> -->
        </v-sheet>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
//   import { mapGetters, mapActions } from 'vuex';
// import { mapGetters } from 'vuex';

export default defineComponent({
    name: 'DashboardView',
    data() {
        return {
            form: {
                title: '',
                content: '',
            },
        };
    },
    computed: {
        // ...mapGetters([{ campers: 'stateCampers'}, {helpers: 'stateHelpers'}]),
        // getNumOfPeople() {
        //     let _count = 0;
        //     for(let i=0; i < this.campers.length; i++) {
        //         _count += this.campers[i].num_of_persons;
        //     }
        //     return _count;
        // },
        // getNumOfVehicles() {
        //     let _count = 0;
        //     for(let i=0; i < this.campers.length; i++) {
        //         _count += this.campers[i].num_of_vehicles;
        //     }
        //     return _count;
        // },
        // getNumOfTents() {
        //     let _count = 0;
        //     for(let i=0; i < this.campers.length; i++) {
        //         _count += this.campers[i].num_of_tents;
        //     }
        //     return _count;
        // }
    },
    methods: {
        ...mapActions(['setToken'])
        //   ...mapActions(['createNote']),
        //   async submit() {
        //     await this.createNote(this.form);
        //   },
    },
});
</script>
<style >
    img {
        cursor: pointer;
    }
</style>