import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView.vue';
import ProfileView from '@/views/ProfileView.vue';
import CampersView from '@/views/CampersView.vue';
import HelpersView from '@/views/HelpersView.vue';
import TeamsView from '@/views/TeamsView.vue';
import TeamManagementView from '@/views/TeamManagementView.vue';
import PersonView from '@/views/PersonView.vue';

import store from '@/store';

const routes = [
  {
    path: '/',
    name: "Home",
    component: HomeView,
    meta: {
      roles: [],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      roles: [],
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { 
      requiresAuth: true,
      roles: ['admin', 'teammanager', 'staff'],
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: { 
      requiresAuth: true,
      roles: ['admin', 'staff', 'teammanager'],
    },
  },
  {
    path: '/campers',
    name: 'Camper',
    component: CampersView,
    meta: { 
      requiresAuth: true,
      roles: ['admin', 'staff'],
    },
  },
  {
    path: '/personen',
    name: 'Personen',
    component: PersonView,
    meta: { 
      requiresAuth: true,
      roles: ['admin', 'staff'],
    },
  },
  {
    path: '/helpers',
    name: 'Helfer',
    component: HelpersView,
    meta: { 
      requiresAuth: true,
      roles: ['admin', 'staff'],
    },
  },
  {
    path: '/teams',
    name: 'Teams',
    component: TeamsView,
    meta: { 
      requiresAuth: true,
      roles: ['admin', 'staff', 'teammanager'],
    },
  },
  {
  path: '/myTeams',
    name: 'Team Management',
    component: TeamManagementView,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'teammanager']
    }
  }
  // {
  //   path: '/team/:id',
  //   name: 'Team Players',
  //   component: TeamView,
  //   meta: {
  //     requiresAuth: true,
  //     roles: ['staff', 'teammanager']
  //   }
  

  // {
  //   path: '/camper',
  //   name: 'Dashboard',
  //   component: DashboardView,
  //   meta: { requiresAuth: true },
  // },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const _required = !!to.meta.requiresAuth;
  const _requiredRoles = to.meta.roles;
  const _sessionToken = sessionStorage.getItem('userToken') === null ? null : sessionStorage.getItem('userToken');
  const _userRoles = store.state.users.roles === null ? null: store.state.users.roles;
  const _usertoken = store.state.users.token === null ? null : store.state.users.token;

  if(_required) {
    if(_sessionToken === null) {
      next('/login');
    } else if(_sessionToken !== null && _usertoken === null) {
      return store.dispatch('viewMe').then(() => {
        store.commit('setToken', _sessionToken);
        return next();
      }).catch(() => {
        store.dispatch('logOut');
        return next('/login');
      });
    } else if(_sessionToken !== null && _usertoken !== null && _userRoles !== null) {
      let _authed = false;
      if(_required) {
        for(let role of _requiredRoles) {
          if(_userRoles.includes(role)) {
            _authed = true;
          }
        }
        if(_authed) {
          next();
        } else {
          next('/login');
        }
      } else {
        next();
      }
    } 
  } else {
    if(to.name === 'Login' && _sessionToken) {
      next('/dashboard');
    } else {
      next();
    }
  }
  
});

export default router