import axios from 'axios';

const state = {
    user: null,
    token: null,
    roles: null
};

const getters = {
    isAuthenticated: state => {
        const _sessionToken = sessionStorage.getItem('userToken');
        if(state.token !== null && _sessionToken !== null) {
            return true;
        } else if(_sessionToken !== null) {
            return true;
        } else {
            return false;
        }
    },
    stateUser: state => state.user,
    token: state => state.token,
    getToken: state => {
        return state.token;
    },
    getRoles: state => {
        return state.roles;
    }
};

const actions = {
//   async register({dispatch}, form) {
//     await axios.post('register', form);
//     let UserForm = new FormData();
//     UserForm.append('username', form.username);
//     UserForm.append('password', form.password);
//     await dispatch('logIn', UserForm);
//   },
    logIn(context, user) {
        return axios.post('token', 
            {
                'username': user.username,
                'password': user.password,  
                'grant_type': 'password',
                'scope': ''
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((result) => {
            context.commit('setToken', result.data.access_token);
            return context.dispatch('viewMe').then(() => {
                return new Promise((resolve) => {
                    resolve({'status': 'okay', 'message': 'store uses loggedin'})
                });
            });

        }).catch((e) => {
            return new Promise((resolve, reject) => {
                reject({'status': 'error', 'message': e.message});
            })
        });

    },

    viewMe(context) {
        return axios.get('users/me')
        .then((response) => {
            const _user = response.data
            context.commit('setUser', _user);
            context.commit('setRoles', _user.roles);
            return new Promise((resolve) => {
                resolve({'status': 'okay', 'message': 'view me success'});
            });
        })
        .catch((e) => {
            context.dispatch('logOut');
            return new Promise((resolve, reject) => {
                reject({'status': 'error', 'message': e.message});
            })
        });
    },
//   // eslint-disable-next-line no-empty-pattern
//   async deleteUser({}, id) {
//     await axios.delete(`user/${id}`);
//   },
    
    logOut({commit}) {
        const user = null;
        commit('logout', user);
        return new Promise((resolve) => {
            resolve({'status': 'okay'});
        })
    }
};

const mutations = {
    setUser(state, user) {
        state.user = user;        
    },  
    logout(state){
        state.user = null;
        state.token = null;
        sessionStorage.removeItem('userToken');
        sessionStorage.removeItem('userRoles');
    },
    setToken(state, token) {
        state.token = token;
        sessionStorage.setItem('userToken', token);
    },
    setRoles(state, roles) {
        state.roles = roles;
        sessionStorage.setItem('userRoles', roles);
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};