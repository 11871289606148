import axios from 'axios';

const state = {
    helpers: null,
    helper: null
};

const getters = {
    stateHelpers: state => state.helpers,
    stateHelper: state => state.helper,
};

const actions = {
    //   async createNote({dispatch}, note) {
    //     await axios.post('notes', note);
    //     await dispatch('getNotes');
    //   },
    getHelpers({ commit }) {
        axios.get('/helpers').then((result) => {
            commit('setHelpers', result.data);
        }).catch((error) => {
            console.log('error getting helpers: %o', error);
        });
    },
    viewHelper({ commit }, id) {
        axios.get(`/helpers/${id}`).then((result) => {
            commit('setHelpers', result.data);
        }).catch((error) => {
            console.log('error getting helpers: %o', error);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    //   async updateNote({}, note) {
    //     await axios.patch(`note/${note.id}`, note.form);
    //   },
    // eslint-disable-next-line no-empty-pattern
    //   async deleteNote({}, id) {
    //     await axios.delete(`note/${id}`);
    //   }
};

const mutations = {
    setHelpers(state, helpers) {
        state.helpers = helpers;
    },
    setHelper(state, helper) {
        state.helper = helper;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};