import { createStore } from "vuex";

import helpers from './modules/helpers';
import camping from './modules/camping';
import users from './modules/users';
import teams from './modules/teams';
import myTeams from './modules/teammanagement';

export default createStore({
    modules: {
        camping: camping,
        helpers: helpers,
        teams: teams,
        users: users,
        myTeams: myTeams
    }
});