import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import { createApp } from "vue";
import './middleware/axios-request-interceptor';
import store from './store'; // New

import App from './App.vue';
import router from './router';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { VDataTable } from 'vuetify/labs/VDataTable'

const vuetify = createVuetify({
    components: { ...components, VDataTable },
    directives,
})

const app = createApp(App);

app.use(store); 
app.use(router);
app.use(vuetify);

router.isReady().then(() => {
    app.mount('#app');
});

